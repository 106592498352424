import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getTokenFromStorage } from 'utils/localStorage';
import { baseApiUrl } from 'utils/constants';

export const api: any = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
    prepareHeaders: headers => {
      const token = getTokenFromStorage();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    login: builder.mutation({
      query: body => ({
        url: `auth/v2/login/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
    }),
    getUser: builder.mutation({
      query: () => ({
        url: `dashboard/user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    changeStatus: builder.mutation({
      query: ({ params, endpoint }) => ({
        url: `dashboard/application/${endpoint}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: params,
      }),
    }),
    getManagers: builder.mutation({
      query: () => ({
        url: `dashboard/manager/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getStatuses: builder.mutation({
      query: () => ({
        url: `dashboard/application/statuses/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getDealershipsList: builder.mutation({
      query: ({ queries }) => ({
        url: `dashboard/dealerships/select/${queries}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getExportFile: builder.mutation({
      query: ({ queries }) => ({
        url: `dashboard/report/${queries}`,
        method: 'GET',
        responseHandler: response => response.blob(),
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserMutation,
  useChangeStatusMutation,
  useGetManagersMutation,
  useGetDealershipsListMutation,
  useGetStatusesMutation,
  useGetExportFileMutation,
} = api;
