import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { OutlinedInput, Button } from '@mui/material';
import { PageTemplate } from 'components/PageTemplate';
import { setMainLoader } from 'store/slices/mainLoader';
import { DatepickerIcon } from 'assets/icons';
import { MessageNotice } from 'components/MessageNotice';
import { InputWrapper } from 'components/Search/styles';
import { InputWrappers, RowBlock } from './styles';
import { useGetExportFileMutation } from 'hooks/useApiRequest';
import { downloadResponse } from 'utils/dataTransformation';

export const Export = () => {
  const [value, setValue] = useState({ with: '', before: '' });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [getFile] = useGetExportFileMutation();
  const [showNotice, setShowNotice] = useState(false);

  const changeInputValue = (event: any, field: string) => {
    setValue({ ...value, [field]: event.target.value });
  };

  const sendRequest = () => {
    try {
      setLoading(true);
      console.log('here');
      const dateStart = moment(value.with, 'DD.MM.YYYY').toDate().toISOString();
      console.log(dateStart);
      const dateEnd = moment(value.before, 'DD.MM.YYYY').toDate().toISOString();
      console.log(dateEnd);

      if (dateStart !== 'error' && dateEnd !== 'error') {
        getFile({
          queries: `?date_start=${dateStart}&date_end=${dateEnd}`,
        })
          .then((res: any) => {
            setMessage('');
            downloadResponse(res, `Заявки от ${value.with} до ${value.before}.xlsx`);

            setTimeout(() => {
              setLoading(false);
            }, 250);
          })
          .catch((error: any) => {
            setShowNotice(true);
            setMessage(
              (error?.data?.error?.details &&
                error.data.error.details.length &&
                error.data.error.details[0].message) ||
                'Ошибка! При выполнении данного действия что-то пошло не так!',
            );
          });
      } else {
        new Error();
      }
    } catch {
      setLoading(false);
      setMessage('Некорректный формат даты');
      setShowNotice(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setMainLoader(false));
    }, 250);
  }, []);

  const commonInputProps = {
    inputProps: {
      'aria-label': 'weight',
    },
    className: 'input',
    fullWidth: true,
    required: true,
  };

  return (
    <PageTemplate title="Выгрузить все заявки">
      <RowBlock>
        <InputWrappers>
          <InputWrapper variant="outlined">
            <InputMask
              mask={'99.99.9999'}
              value={value.with}
              onChange={e => changeInputValue(e, 'with')}
            >
              {/* @ts-ignore */}
              {(inputProps: any) => (
                <OutlinedInput
                  {...inputProps}
                  {...commonInputProps}
                  name="with"
                  placeholder="с"
                />
              )}
            </InputMask>

            <DatepickerIcon className="icon" />
          </InputWrapper>
          <InputWrapper variant="outlined">
            <InputMask
              mask={'99.99.9999'}
              value={value.before}
              onChange={e => changeInputValue(e, 'before')}
            >
              {/* @ts-ignore */}
              {(inputProps: any) => (
                <OutlinedInput
                  {...inputProps}
                  {...commonInputProps}
                  name="before"
                  placeholder="до"
                />
              )}
            </InputMask>
            <DatepickerIcon className="icon" />
          </InputWrapper>
        </InputWrappers>
        <Button className="button" onClick={sendRequest} disabled={loading}>
          Выгрузить в Excel
        </Button>
        <MessageNotice
          message={message}
          show={showNotice}
          status="error"
          onChange={setShowNotice}
        />
      </RowBlock>
    </PageTemplate>
  );
};
