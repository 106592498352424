import styled from 'styled-components';

export const Content = styled.div`
  border-radius: 12px;
  background: #ffffff;
  margin-top: 38px;
  padding-bottom: 14px;
  position: relative;
`;

export const InputWrappers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  border: 1px solid #e3e8ef;
  width: 370px;

  .MuiFormControl-root {
    &:first-child {
      .input,
      input {
        border-radius: 10px 0 0 10px;
      }
    }
    &:last-child {
      .input,
      input {
        border-radius: 0 10px 10px 0;
        border-left: 1px solid #e3e8ef;
      }
    }
  }
`;

export const RowBlock = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

  .button {
    width: 280px;
    padding: 14px 0;
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 116%;
    outline: none;
    border: none;
    background: #49a3a6;
    border-radius: 40px;
    color: #ffffff;
    text-transform: none;

    &:hover {
      background: #49a3a6;
    }
  }
`;
