import styled, { css } from 'styled-components';

interface PropsStyle {
  filling?: boolean;
  type?: string;
}

export const detailInfoItemStyles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;

    button {
      background: transparent;
      outline: none;
      border: none;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }

    span {
      font-family: 'Open Sans';
      font-style: normal;
      line-height: 140%;
      color: #919ca7;
    }

    .content-info {
      display: flex;
    }

    ${(props: PropsStyle) => {
      switch (props.filling) {
        case true:
          return css`
            padding: 16px;
            gap: 8px;
            background: #eceff2;
            width: 100%;
            border-radius: 10px;
            position: relative;
            ${props.type === 'payment' && 'align-items: center; margin-top: 24px;'}

            span {
              font-family: 'Open Sans Semi Bold';
              font-size: 12px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
            }

            .content-info {
              flex-direction: column;
              ${props.type === 'payment' && 'justify-content: center;'}

              .title-info {
                color: #333333;
                ${props.type === 'textarea'
                  ? 'font-family: "Open Sans"; font-size: 16px; line-height: 140%;'
                  : 'font-size: 30px; font-family: "Open Sans Semi Bold"; line-height: 116%;'}
                text-transform: none;
                display: flex;
                align-items: center;

                svg {
                  height: 30px;
                  margin-left: 3px;
                  fill: #333333;
                  stroke: #333333;
                  stroke-width: 3%;
                }
              }

              .title-info-text {
                white-space: pre-wrap;
              }

              button {
                position: absolute;
                right: 18px;
                ${props.type !== 'payment' && 'top: 16px;'}
              }

              .empty-info {
                font-size: 16px;
                font-family: 'Open Sans';
                line-height: 140%;
                color: #919ca7;
                letter-spacing: normal;
              }
            }
          `;
        default:
          return css`
            width: 45%;

            span {
              font-family: 'Open Sans';
              font-size: 16px;
            }

            .content-info {
              align-items: center;
              gap: 10px;

              span {
                color: #333333;
              }

              .empty-info {
                font-size: 12px;
                margin-top: 10px;
              }
            }
          `;
      }
    }}
  `,
};
