import {
  Home,
  Agents,
  Archive,
  Clients,
  Payments,
  Settings,
  SingleApplication,
  WorkApplications,
  Dealerships,
  Managers,
  Directors,
  Export,
} from 'pages';
import { Redirect } from './Redirect';
import { PrivateRoute } from './PrivateRoute';
import { Roles } from 'shared/roles';

const paths = [
  {
    path: '/application/:id',
    id: 2,
    element: (
      <PrivateRoute>
        <SingleApplication />
      </PrivateRoute>
    ),
  },
  {
    id: 22,
    path: '/application/new',
    title: 'Новые заявки',
    element: <Redirect link="/application/new/with-reg" />,
  },
  {
    id: 221,
    path: '/application/new/with-reg',
    title: 'Новые заявки',
    element: (
      <PrivateRoute>
        <Home param="with-reg" />
      </PrivateRoute>
    ),
  },
  {
    id: 222,
    path: '/application/new/without-reg',
    title: 'Новые заявки',
    element: (
      <PrivateRoute>
        <Home param="without-reg" />
      </PrivateRoute>
    ),
  },
  {
    id: 23,
    title: 'Заявки в работе',
    path: '/application/underway',
    element: <Redirect link="/application/underway/with-reg" />,
  },
  {
    id: 231,
    title: 'Заявки в работе',
    path: '/application/underway/with-reg',
    element: (
      <PrivateRoute>
        <WorkApplications param="with-reg" />
      </PrivateRoute>
    ),
  },
  {
    id: 232,
    title: 'Заявки в работе',
    path: '/application/underway/without-reg',
    element: (
      <PrivateRoute>
        <WorkApplications param="without-reg" />
      </PrivateRoute>
    ),
  },
  {
    id: 24,
    title: 'Архив заявок',
    path: '/application/finished',
    element: (
      <PrivateRoute>
        <Archive />
      </PrivateRoute>
    ),
  },
  {
    id: 3,
    title: 'Клиенты',
    path: '/clients',
    element: <Redirect link="/clients/from-agent" />,
  },
  {
    id: 31,
    title: 'Клиенты',
    path: '/clients/from-agent',
    element: (
      <PrivateRoute>
        <Clients param="from-agent" />
      </PrivateRoute>
    ),
  },
  {
    id: 32,
    title: 'Клиенты',
    path: '/clients/registered',
    element: (
      <PrivateRoute>
        <Clients param="registered" />
      </PrivateRoute>
    ),
  },
  {
    id: 4,
    title: 'Агенты',
    path: '/agents',
    element: (
      <PrivateRoute>
        <Agents />
      </PrivateRoute>
    ),
  },
  {
    id: 5,
    title: 'Выплаты',
    path: '/payments',
    element: (
      <PrivateRoute>
        <Payments />
      </PrivateRoute>
    ),
  },
  {
    id: 6,
    title: 'Редактировать данные',
    path: '/settings',
    element: (
      <PrivateRoute>
        <Settings />
      </PrivateRoute>
    ),
  },
  {
    id: 7,
    title: 'Автосалоны',
    path: '/dealerships',
    element: (
      <PrivateRoute forRole={Roles.ADMIN_ID}>
        <Dealerships />
      </PrivateRoute>
    ),
  },
  {
    id: 8,
    title: 'Менеджеры',
    path: '/managers',
    element: (
      <PrivateRoute forRole={Roles.ADMIN_ID}>
        <Managers />
      </PrivateRoute>
    ),
  },
  {
    id: 9,
    title: 'Директоры',
    path: '/directors',
    element: (
      <PrivateRoute forRole={Roles.ADMIN_ID}>
        <Directors />
      </PrivateRoute>
    ),
  },
  {
    id: 10,
    title: 'Экспорт',
    path: '/export',
    element: (
      <PrivateRoute forRole={Roles.ADMIN_ID}>
        <Export />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    id: 0,
    element: <Redirect link="/application/new/with-reg" />,
  },
];

export default paths;
